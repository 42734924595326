import React, { useState, useEffect } from "react";
import Form from "../PureComponents/Form";
import { Row } from "../Styles/Layout/Row";
import { Header1, Header3, Text, Label } from "../Styles/UIKit/Text";
import { Card } from "../Styles/UIKit/Card";
import { Col } from "../Styles/Layout/Col";
import { calculatePriceFromInquiryDetails, convertUrl } from "../service/helper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextAreaControl from "./TextAreaControl";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";

interface ICustomerInquiryFormProps {
  shopName: string;
  shopAddress: string;
  shopPhone: string;
  inquiryData: any;
  isPreview: boolean;
  isQuote: boolean;
  onSubmit: (notes: string) => Promise<void>;
}

const CustomerEstimateForm = ({
  shopName,
  shopAddress,
  shopPhone,
  inquiryData,
  isPreview,
  isQuote,
  onSubmit,
}: ICustomerInquiryFormProps) => {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit(notes);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getVideoUrl = () => {
    if (inquiryData.inquiry.videoUrls && inquiryData.inquiry.videoUrls.length) {
      // loop through videoUrls and find the first one which object name is videoForCustomer
      const videoForCustomer = inquiryData.inquiry.videoUrls.find(
        (item: any) => item.name === "videoForCustomer"
      );
      if (videoForCustomer) {
        const originUrl = videoForCustomer.url;
        const convertedUrl = convertUrl(originUrl);
        if (convertedUrl) {
          return convertedUrl;
        }
        return "";
      }
      return "";
    }
    return "";
  };

  useEffect(() => {
    setNotes(inquiryData.inquiry.note || "");
  }, [inquiryData]);

  return (
    <Form handleSubmit={handleSubmit} style={{ width: "100%" }}>
      <Row justifyContent="center">
        <Header1 size="1.56rem" weight="700">
          {shopName}
        </Header1>
      </Row>
      <Row margin="0 auto 2rem" padding=".2rem 1.6rem" maxWidth="50rem">
        <Col grow={12} justifyContent="center">
          <Text size="0.875rem" weight="500" textAlign="center">
            {shopAddress || ""}
          </Text>
        </Col>
      </Row>
      <Card style={{ marginBottom: "1rem" }}>
        <Header3 margin="1rem 0" size="1.125rem" weight="700" color="#1B34CE">
          Contact Information
        </Header3>
        <Row>
          <Col grow={1}>
            <Label weight="500">{inquiryData.contactInfo.name}</Label>
          </Col>
        </Row>
        <Row>
          <Col grow={1}>
            <Label weight="500">{inquiryData.contactInfo.phone}</Label>
          </Col>
        </Row>
        <Row>
          <Col grow={1}>
            <Label weight="500">{inquiryData.contactInfo.email}</Label>
          </Col>
        </Row>
      </Card>
      <Card style={{ marginBottom: "1rem" }}>
        <Header3 margin="1rem 0" size="1.125rem" weight="700" color="#1B34CE">
          Vehicle Information
        </Header3>
        <Row>
          <Col grow={1}>
            <Label weight="500">{inquiryData.inquiry.carModel}</Label>
          </Col>
        </Row>
        <Row>
          <Col grow={1}>
            <Label weight="500"></Label>
          </Col>
        </Row>
      </Card>
      <video
        width="100%"
        controls
        style={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
      >
        <source src={getVideoUrl()} type="video/mp4" />
      </video>
      <Card style={{ marginBottom: "1rem" }}>
        <Header3 margin="1rem 0" size="1.125rem" weight="700" color="#1B34CE">
          Service Information
        </Header3>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "1rem",
            mb: "1rem",
          }}
        >
          <Stack>
            <Typography variant="subtitle2" gutterBottom component="div">
              Droppe Off
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold", mt: "-0.5rem" }}
            >
              {moment(inquiryData.inquiry.createdAt).format("MMM Do") || ""}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" gutterBottom component="div">
              Job Type
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold", mt: "-0.5rem" }}
            >
              {inquiryData.inquiry.service || ""}
            </Typography>
          </Stack>
        </Box>
        {inquiryData.inquiryDetail.map((item: any, index: number) => (
          <React.Fragment key={index}>
            <Row>
              <Col grow={6}>
                <Label weight="500" color="#677489">
                  {item.description}
                </Label>
              </Col>
              <Col grow={6} justifyContent="flex-end">
                <Label weight="500" color="#111729">
                  {`$${item.price.toFixed(2)}`}
                </Label>
              </Col>
            </Row>
          </React.Fragment>
        ))}
        <Box sx={{ mx: "0" }}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "bold", color: "#677489", mt: "1rem" }}
          >
            + Additional Notes
          </Typography>
          <TextAreaControl
            required
            label=""
            value={notes}
            placeholder="In-person vehicel delivery to 200 5th Ave."
            sx={{ px: 0, mt: "-0.75rem" }}
            style={{ fontSize: "13px" }}
            handleValueChange={setNotes}
          />
        </Box>
      </Card>
      <Card>
        {!isQuote && (
          <>
            <Row>
              <Col grow={6}>
                <Label weight="600" color="#677489">
                  Deposit ({inquiryData.inquiryDetail[0].percent}%)
                </Label>
              </Col>
              <Col grow={6} justifyContent="flex-end">
                <Label weight="600, Semi Bold" color="#111729">
                  {
                    calculatePriceFromInquiryDetails(
                      inquiryData.inquiryDetail,
                      true,
                      isQuote
                    ).total
                  }
                </Label>
              </Col>
            </Row>
            <Row>
              <Col grow={6}>
                <Label weight="600, Semi Bold" color="#677489">
                  Convenience Fee
                </Label>
              </Col>
              <Col grow={6} justifyContent="flex-end">
                <Label weight="600, Semi Bold" color="#111729">
                  {
                    calculatePriceFromInquiryDetails(
                      inquiryData.inquiryDetail,
                      inquiryData.inquiry.feeFromShop,
                      isQuote
                    ).fee
                  }
                </Label>
              </Col>
            </Row>
          </>
        )}
        <Divider />
        <Row>
          <Col grow={6}>
            <Label weight="700" size="1rem">
              Total
            </Label>
          </Col>
          <Col grow={6} justifyContent="flex-end">
            <Label weight="700" size="1rem" color="#111729">
              {
                calculatePriceFromInquiryDetails(
                  inquiryData.inquiryDetail,
                  inquiryData.inquiry.feeFromShop,
                  isQuote
                ).total
              }
            </Label>
          </Col>
        </Row>
      </Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          my: "1rem",
        }}
      >
        <LoadingButton
          variant="contained"
          sx={{ bgcolor: "#1B34CE", textTransform: "none" }}
          onClick={() => {
            if (shopPhone) {
              window.open(`tel:${shopPhone}`);
            }
          }}
        >
          Message Us
        </LoadingButton>
        {!isPreview && (
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{ bgcolor: "#5B9104", textTransform: "none" }}
          >
            {!loading ? "Confirm" : "Sending..."}
          </LoadingButton>
        )}
      </Box>
    </Form>
  );
};

export default CustomerEstimateForm;
