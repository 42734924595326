/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container } from "../Styles/Layout/Container";
import CustomerInvoiceForm from "../Components/CustomerInvoiceForm";
import jobsAPI from "../ClientAPI/jobsAPI";
import StripeCheckout from "../Components/Stripe/index";

interface ICustomerInvoicePageProps {
  queryVal: string;
}

const CustomerInvoicePage = ({ queryVal }: ICustomerInvoicePageProps) => {
  const [loaded, setLoaded] = useState(false);
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [shopPhone, setShopPhone] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [hasPaymentIntent, setHasPaymentIntent] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  let contactInfo: object;
  let serviceInfo: object[] = [];
  let total;
  let vehicleInfo;
  let jobInfo;

  useEffect(() => {
    const url = queryVal.split("pre_");
    let invoiceKey = queryVal;
    if (url.length === 2) {
      invoiceKey = url[1];
      setIsPreview(true);
    }
    jobsAPI
      .validateToken(invoiceKey)
      .then((data) => {
        setShopName(data.shopData.businessName || "");
        setShopAddress(data.shopData.businessAddress || "");
        setShopPhone(data.shopData.shopTelephone);
        setSubmitted(data.status === "submitted");
        if (data.status !== "submitted") getInvoiceInfo(data);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });
  }, []);

  const getInvoiceInfo = (data: any) => {
    contactInfo = {
      name:
        capitalizeFirstLetter(data.contactInfo.firstName) +
        " " +
        capitalizeFirstLetter(data.contactInfo.lastName),
      phone: data.contactInfo.phone,
      email: data.contactInfo.email,
    };
    data.jobDetails.map((item: any) => {
      serviceInfo.push({ service: item.description, price: item.price });
    });
    total = data.total;
    vehicleInfo =
      data.vehicle.modelYear +
      " " +
      data.vehicle.make +
      " " +
      data.vehicle.carModel;
    jobInfo = { vehicleInfo: vehicleInfo, vin: data.vehicle.vin };
    data = {
      contactInfo: contactInfo,
      jobInfo: jobInfo,
      serviceInfo: serviceInfo,
      total: total,
      feeFromShop: data.feeFromShop,
      inquiryPaidDetail: data.inquiryPaidDetail,
    };
    setInvoiceData(data);
  };

  const submitInvoice = async () => {
    try {
      const response = await jobsAPI.confirmInvoice(queryVal);
      if (response.status === 200) {
        // setSubmitted(true);
        const paymentIntentData = await jobsAPI.getPaymentIntent(queryVal);
        if (paymentIntentData) {
          setPaymentIntent(paymentIntentData);
          setHasPaymentIntent(true);
        }
      }
    } catch (err: any) {
      window.alert(err.message);
    }
  };

  function capitalizeFirstLetter(aname: String) {
    return aname.charAt(0).toUpperCase() + aname.slice(1);
  }

  return (
    <Container>
      {!hasPaymentIntent ? (
        <Container>
          {submitted ? (
            <h1 className="main-header">Successfully Submitted</h1>
          ) : loaded ? (
            shopName && invoiceData !== null ? (
              <CustomerInvoiceForm
                shopName={shopName}
                shopAddress={shopAddress}
                shopPhone={shopPhone}
                invoiceData={invoiceData}
                isPreview={isPreview}
                onSubmit={submitInvoice}
              />
            ) : (
              <h1 className="main-header">
                Oops…page expired, text shop again to receive new form
              </h1>
            )
          ) : (
            <h1 className="main-header">Loading...</h1>
          )}
        </Container>
      ) : (
        <StripeCheckout intent={paymentIntent} />
      )}
    </Container>
  );
};

export default CustomerInvoicePage;
